import router from './index'
import User from "../api/user"

const whiteList = ['/', '/restaurant', '/404' , '/500'] // no redirect whitelist
const isWhiteList = path => {
  let whited = false
  for (let i = 0; i < whiteList.length; i++) {
    if (path === whiteList[i]) {
      whited = true
      break
    }
  }
  if( path.startsWith("/order/")) {
    whited = true
  }
  return whited
}

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || "大师餐酒"
  if (User.getToken()) {
    next()
  } else {
    if( isWhiteList(to.path)) {
      next()
    } else {
      next("/404")
    }
  }
})