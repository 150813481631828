import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import { Lazyload } from 'vant';
import './router/permission'
import 'vant/lib/index.css';
import './css/common.scss'

createApp(App)
    .use(store)
    .use(router)
    .use(Vant)
    .use(Lazyload)
    .mount('#app')
