import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: "大师餐酒-餐厅列表" },
    component: () => import('../views/Restaurant.vue')
  },
  {
    path: '/restaurant',
    name: 'Restaurant',
    meta: { title: "大师餐酒-餐厅列表" },
    component: () => import('../views/Restaurant.vue')
  },
  {
    path: '/restaurant/:id',
    name: 'RestaurantDetails',
    meta: { title: "大师餐酒-餐厅详情" },
    component: () => import('../views/RestaurantDetails.vue')
  },
  {
    path: '/restaurant/:id/booking',
    name: 'RestaurantBooking',
    meta: { title: "大师餐酒-餐厅预定" },
    component: () => import('../views/RestaurantBooking.vue')
  },
  {
    path: '/restaurant/:id/booking-senior-custom',
    name: 'RestaurantBookingSeniorCustom',
    meta: { title: "大师餐酒-餐厅预定选择" },
    component: () => import('../views/RestaurantBookingSenior.vue')
  },
  {
    path: '/restaurant-booking-details/:id',
    name: 'RestaurantBookingDetails',
    meta: { title: "大师餐酒-预约订单详情" },
    component: () => import('../views/RestaurantBookingDetails.vue')
  },
  {
    path: '/order/:orderId',
    name: 'RestaurantBookingOrderDetails',
    meta: { title: "大师餐酒-预约订单详情" },
    component: () => import('../views/RestaurantBookingDetails.vue')
  },
  {
    path: '/my',
    name: 'Mine',
    meta: {},
    component: () => import('../views/my/Mine.vue')
  },
  {
    path: '/my/bookings',
    name: 'MyBookings',
    meta: {},
    component: () => import('../views/my/MyBookings.vue')
  },{
    path: '/my/questions',
    name: 'MyQuestions',
    meta: {},
    component: () => import('../views/my/MyQuestions.vue')
  },{
    path: '/404',
    name: 'NotFoundPage',
    meta: {},
    component: () => import('../views/404.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory("/"),
  routes
})

export default router
