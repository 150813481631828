import { post } from "../utils/http-h5"
import { setEcJumpUrl } from "../utils/ec"

const LOCAL_USER_TOKEN_KEY = "ec_user_token";
const LOCAL_USER_CLIENT_KEY = "ec_user_client";
const LOCAL_PRODUCT_ID_KEY = "ec_product_id";
const LEVELS = {
    "1": "black_gold",
    "2": "white_gold"
};

function getToken() {
    return sessionStorage.getItem(LOCAL_USER_TOKEN_KEY);
}

function isLogin() {
    return new Promise((resolve, reject) => {
        let token = getToken();
        if (token) {
            resolve(token)
        } else {
            reject({
                errorCode: 401
            });
        }
    });
}

function setToken(token) {
    sessionStorage.setItem(LOCAL_USER_TOKEN_KEY, token);
}

function ecLogin({ code, client, cardProductName, cardLevel, cardBin, cardId }) {
    const url = "/v1/ec/member";
    const data = { code, client, cardProductName, cardLevel, cardBin, cardId };
    return post({ url, data });
}

function login({ code, client, supplierBenefitProductId, jumpUrl}) {
    return new Promise((resolve, reject) => {
        if (code && client) {
            const data = { code, client }
            ecLogin(data).then(token => {
                setToken(token);
                setClient(client);
                setProductId(supplierBenefitProductId);
                setEcJumpUrl(jumpUrl);
                resolve(token);
            }, reject);
        } else {
            let token = getToken();
            if (token) {
                resolve(token)
            } else {
                reject({ errorCode: 401 });
            }
        }
    })
}

function logout() {
    sessionStorage.removeItem(LOCAL_USER_TOKEN_KEY);
}

function getClient() {
    return sessionStorage.getItem(LOCAL_USER_CLIENT_KEY);
}
function setClient(client) {
    sessionStorage.setItem(LOCAL_USER_CLIENT_KEY, client);
}

function getProductId() {
    return sessionStorage.getItem(LOCAL_PRODUCT_ID_KEY) || "2";
}
function setProductId(productId) {
    sessionStorage.setItem(LOCAL_PRODUCT_ID_KEY, productId);
}

function getLevel() {
    let level = LEVELS[getProductId()];
    return level;
}

function isBlackGold() {
    let level = getLevel();
    return level === 'black_gold';
}

export default {
    login,
    logout,
    getToken,
    setToken,
    isLogin,
    getClient,
    getProductId,
    getLevel,
    isBlackGold
}