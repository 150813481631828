const LOCAL_JUMP_URL_KEY = "ec_jump_url";

export function initEcScript() {
}

function getEcJumpUrl() {
    return sessionStorage.getItem(LOCAL_JUMP_URL_KEY);
}

export function setEcJumpUrl(url) {
    sessionStorage.setItem(LOCAL_JUMP_URL_KEY, decodeURIComponent(url));
}

function buildUrl(pageType, orderNo) {
    let jumpUrl = getEcJumpUrl();
    jumpUrl = jumpUrl.replace("[orderNo]", orderNo);
    jumpUrl = jumpUrl.replace("[pageType]", pageType);
    return jumpUrl;
}

function goEcPage(pageType, orderNo) {
    return new Promise((resolve) => {
        let url = buildUrl(pageType, orderNo);
        window.location.replace(url);
        resolve({});
    });
}

export function goEcPayOrder(orderNo) {
    return goEcPage("PAGE_PAY", orderNo);
}

export function goEcOrderDetails(orderNo) {
    return goEcPage("PAGE_ORDER_DETAIL", orderNo);
}

export function goEcHome() {
    return goEcPage("PAGE_INDEX", "");
}